<template>
  <div class="pageContent">
    <noNavHeader :title="shopDetails.store_name"/>
    <div class="shopCenter">
      <el-row :gutter="20">
        <el-col :span="5">
          <div class="shopInfo">
            <p class="shopInfoTitle">店铺简介</p>
            <div class="shopInfoContent">
              <div class="shopLogo">
                <img v-if="shopDetails.logo_url" :src="shopDetails.logo_url" />
                <p v-else>暂无LOGO</p>
              </div>
              <p class="shopName">
                店铺名称：
                <span>{{ shopDetails.store_name }}</span>
              </p>
              <p class="shopType">
                店铺类型：
                <span>{{ shopDetails.value }}</span>
              </p>
              <div class="shopInto">
                <p class="shopIntoTitle">店铺介绍：</p>
                <p
                  class="shopIntoText"
                >{{ shopDetails.note }}</p>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="19">
          <div class="shopResource">
            <p class="shopResourceTitle">店铺资源</p>
            <div class="shopResourceContent">
              <el-tabs v-model="activeTabName">
                <el-tab-pane label="产品预售资源" name="preGoodsList">
                  <preGoodsList :shopId="shopId" />
                </el-tab-pane>
                <el-tab-pane label="现货销售" name="goodsList">
                  <goodsList />
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import noNavHeader from "../base/header/noNavHeader";
import goodsList from "./com/goodsList";
import preGoodsList from "./com/preGoodsList";
export default {
  data() {
    return {
      activeTabName: "preGoodsList",
      shopDetails: {},
      shopId: window.sessionStorage.getItem("shopId")
    };
  },
  components: {
    noNavHeader,
    goodsList,
    preGoodsList
  },
  mounted() {
    this.getShopDetails();
  },
  methods: {
    getShopDetails() {
      protocolFwd.param_queryStore.param.firmId = window.sessionStorage.getItem("shopId");
      http.postFront(protocolFwd.param_queryStore).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.shopDetails = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
  }
};
</script>
<style scoped>
.shopCenter {
  width: 80%;
  min-width: 1300px;
  margin: 0 auto;
}
.shopInfo,
.shopResource {
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
}
.shopInfoContent .shopLogo {
  width: 60%;
  height: 80px;
  margin: 30px auto;
  text-align: center;
}
.shopInfoContent .shopLogo img {
  max-width: 100%;
  max-height: 100%;
}
.shopInfoContent .shopLogo p {
  line-height: 80px;
  text-align: center;
  color: #ffffff;
  background-color: #cccccc;
  font-weight: 700;
}
.shopInfoContent .shopName span,
.shopInfoContent .shopType span,
.shopInfoContent .shopInto .shopIntoText {
  line-height: 25px;
  color: #9999a2;
}
.shopResourceContent {
  margin-top: 20px;
}
.shopInfoTitle,
.shopResourceTitle {
  font-size: 16px;
  font-weight: 700;
}
</style>
