<template>
  <div class="goodsList">
    <div class="sort">
      <span class="sortItem active">默认</span>
      <span class="sortItem">
        价格
        <i class="fa fa-long-arrow-up" aria-hidden="true"></i>
      </span>
      <span class="sortItem">
        更新时间
        <i class="fa fa-long-arrow-up" aria-hidden="true"></i>
      </span>
    </div>
    <div class="productionList">
      <div class="item" v-for="i in 10" :key="i">
        <el-row>
          <el-col :span="8">
            <div class="firstCloumn">
              <p>锌卷 SGCC 0.5*1250*C 万达</p>
              <p>重庆蓉贵商贸有限公司</p>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="secondCloumn">
              <p>成套库</p>
              <p>天津 | 10件</p>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="thirdCloumn">
              <p class="date">
                <span class="icon">票</span>
                <span>当月15号</span>
              </p>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="fourthCloumn">
              <p class="price">
                <span>3710元</span>/吨
              </p>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="fifthCloumn">
              <el-button type="danger" @click="showDetails()">查看详情</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-dialog v-dialogDrag title="资源详情" :visible.sync="dialogVisible" width="670px">
      <div class="orderDetails">
        <el-row>
          <el-col :span="4">
            <span class="listTitle">商品分类：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">锌卷 SGCC 0.5*1250*C</span>
          </el-col>
          <el-col :span="4">
            <span class="listTitle">价格：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">3710元</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <span class="listTitle">数量：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">10件</span>
          </el-col>
          <el-col :span="4">
            <span class="listTitle">产地：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">天津</span>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false
    };
  },
  methods: {
    showDetails() {
      this.dialogVisible = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.goodsList .sort {
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #f2f2f2;
}
.goodsList .sort .sortItem {
  margin-right: 10px;
}
.goodsList .sort .sortItem.active {
  color: $commonThemeColor;
}
.productionList .item {
  padding: 15px 0;
  border-bottom: 1px dashed #f2f2f2;
}
.productionList .item .secondCloumn,
.productionList .item .thirdCloumn,
.productionList .item .fourthCloumn,
.productionList .item .fifthCloumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.productionList .item .date .icon {
  padding: 0 5px;
  color: #ffffff;
  background-color: #6990ec;
}
.orderDetails {
  line-height: 35px;
}
.orderDetails .listTitle {
  display: block;
  text-align: right;
}
.orderDetails .listContent {
  margin-left: 5px;
}
</style>
